import React, { PureComponent } from 'react'
import {
  Button,
  Icon,
  Spin,
  Drawer,
  Popconfirm,
  Divider,
} from 'antd'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { EditOutlined, CopyOutlined, DeleteOutlined } from '@ant-design/icons'
import moment from 'moment'

import {
  getPromocodesList,
  copyPromocode,
  deletePromocode,
  openPromocodeForm,
  closePromocodeForm,
  openEditPromocodeForm,
} from '../../../redux/actions/PromocodesActions'
import hasUserAccess from '../../../utils/hasUserAccess'

import PromocodeForm from './Components/PromocodeFormComponent'
import AnimatedTable from '../../../components/AnimatedTableComponent'

class Promocodes extends PureComponent {
  handleTableChange = (pagination, filters, sorter) => {
    let sort = null
    if (Object.keys(sorter).length > 0) {
      sort = {
        field: sorter.field,
        type: sorter.order === 'ascend' ? 'asc' : 'desc',
      }
    }
    this.props.getPromocodesList(1, sort)
  }

  handleScroll = e => {
    if (
      this.props.promocodes.isLoadingPromocodes ||
      this.props.promocodes.isTotalReached
    ) {
      return
    }

    const body = document.body,
      html = document.documentElement

    const wholePageHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    )

    const scrollTop = window.scrollY
    const windowHeight = window.innerHeight

    if (scrollTop > wholePageHeight - windowHeight * 1.05) {
      const pageNumber = this.props.promocodes.pageNumber + 1
      this.props.getPromocodesList(
        pageNumber,
        this.props.promocodes.sort
      )
    }
  }

  componentDidMount() {
    this.props.getPromocodesList(1, this.props.promocodes.sort)
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  render() {
    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />

    const { user, promocodes } = this.props

    const data = promocodes.promocodes.map(item => ({
      key: item.id,
      id: item.id,
      discount: item.discount + '%',
      code: item.code,
      date_valid_from: moment(item.date_valid_from).format('DD.MM.YYYY'),
      date_valid_to: moment(item.date_valid_to).format('DD.MM.YYYY'),
      usage_count: item.usage_count,
    }))

    const onCellActions = (record, rowIndex) => ({
      onClick: event => {
        this.props.openEditPromocodeForm(record.id)
      }
    })

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        sorter: true,
        className: 'Activity__table-cell',
        width: 100,
        onCell: onCellActions,
      },
      {
        title: 'Промокод',
        dataIndex: 'code',
        sorter: true,
        className: 'Activity__table-cell',
        onCell: onCellActions,
      },
      {
        title: 'Скидка',
        dataIndex: 'discount',
        sorter: true,
        className: 'Activity__table-cell',
        onCell: onCellActions,
      },
      {
        title: 'Дата действия с',
        dataIndex: 'date_valid_from',
        sorter: true,
        className: 'Activity__table-cell',
        onCell: onCellActions,
      },
      {
        title: 'Дата действия до',
        dataIndex: 'date_valid_to',
        sorter: true,
        className: 'Activity__table-cell',
        onCell: onCellActions,
      },
      {
        title: 'Использований',
        dataIndex: 'usage_count',
        sorter: true,
        className: 'Activity__table-cell',
        onCell: onCellActions,
      },
      {
        title: '',
        key: 'action',
        width: 130,
        render: (text, record) => (
          <span className="Activity__control-buttons">
            {hasUserAccess(user.accessSettings, 'create_promocodes') && (
              <a
                href="/"
                title="Копировать"
                onClick={e => {
                  e.preventDefault()
                  this.props.copyPromocode(record.id)
                }}
              >
                <CopyOutlined />
              </a>
            )}
            {hasUserAccess(user.accessSettings, 'update_promocodes') && (
              <>
                <Divider type="vertical" />
                <a
                  href="/"
                  onClick={e => {
                    e.preventDefault()
                    this.props.openEditPromocodeForm(record.id)
                  }}
                >
                  <EditOutlined />
                </a>
              </>
            )}
            {
              record.usage_count === 0 &&
              hasUserAccess(user.accessSettings, 'delete_promocodes') &&
              (
                <>
                  <Divider type="vertical" />
                  <Popconfirm
                    title="Вы уверены, что хотите удалить запись?"
                    onConfirm={e => this.props.deletePromocode(record.id)}
                    okText="Да"
                    cancelText="Нет"
                  >
                    <a href="/">
                      <DeleteOutlined />
                    </a>
                  </Popconfirm>
                </>
              )}
          </span>
        ),
      },
    ]

    let tableStyle = null
    if (
      promocodes.isLoadingPromocodes &&
      promocodes.pageNumber === 1
    ) {
      tableStyle = { display: 'none' }
    }

    return (
      <div>
        <Helmet>
          <title>Прококоды</title>
        </Helmet>

        <h1>
          Промокоды
          {hasUserAccess(user.accessSettings, 'create_promocodes') && (
            <Button
              type="primary"
              className="Activity__add-button"
              onClick={this.props.openPromocodeForm}
            >
              Создать
            </Button>
          )}
        </h1>

        <AnimatedTable
          columns={columns}
          dataSource={data}
          pagination={false}
          onChange={this.handleTableChange}
          style={tableStyle}
          animation={promocodes.animation}
        />

        {promocodes.isLoadingPromocodes && (
          <div className="Activity__loader">
            <Spin indicator={antIcon} />
          </div>
        )}

        <Drawer
          title={
            !promocodes.promocodeForm.id ? 'Создание' : 'Редактирование'
          }
          width={'700'}
          onClose={this.props.closePromocodeForm}
          visible={promocodes.promocodeForm.isOpen}
        >
          <PromocodeForm />
        </Drawer>
      </div>
    )
  }
}

const mapStateToProps = store => {
  return {
    promocodes: store.promocodes,
    user: store.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getPromocodesList: (pageNumber = 1, sort = null) =>
      dispatch(getPromocodesList(pageNumber, sort)),
    copyPromocode: id => dispatch(copyPromocode(id)),
    deletePromocode: id => dispatch(deletePromocode(id)),
    openPromocodeForm: () => dispatch(openPromocodeForm()),
    closePromocodeForm: () => dispatch(closePromocodeForm()),
    openEditPromocodeForm: id => dispatch(openEditPromocodeForm(id)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Promocodes)