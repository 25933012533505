import { combineReducers } from 'redux'
import user from './user'
import server from './server'
import tickets from './tickets'
import settings from './settings'
import common from './common'
import report from './report'
import order from './order'
import cashier from './cashier'
import subscriptions from './subscriptions'
import certificates from './certificates'
import promocodes from './promocodes'
import orders from './orders'

export default combineReducers({
  user,
  server,
  tickets,
  settings,
  common,
  report,
  order,
  cashier,
  subscriptions,
  certificates,
  promocodes,
  orders,
})
