import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  Form,
  Button,
  Icon,
  Spin,
  InputNumber,
  Input,
  DatePicker
} from 'antd'
import moment from 'moment'

import {
  setFieldForPromocode,
  createPromocode,
  updatePromocode,
} from '../../../../redux/actions/PromocodesActions'

import hasUserAccess from '../../../../utils/hasUserAccess'

class PromocodeForm extends PureComponent {
  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { promocodeForm } = this.props.promocodes
        let formObj = {}

        Object.keys(promocodeForm).forEach(item => {
          if (
            promocodeForm[item] !== null &&
            item !== 'id' &&
            item !== 'isOpen' &&
            item !== 'isSending'
          ) {
            formObj[item] = promocodeForm[item]
          }
        })

        if (!promocodeForm.id) {
          this.props.createPromocode(formObj)
        } else {
          this.props.updatePromocode(promocodeForm.id, formObj)
        }
      }
    })
  }

  render() {
    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />

    const { getFieldDecorator } = this.props.form
    const { user } = this.props

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    }

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    }

    const promocodeForm = this.props.promocodes.promocodeForm
    const disableAll = !hasUserAccess(user.accessSettings, 'update_promocodes')

    return promocodeForm.isOpen ? (
      <Spin spinning={promocodeForm.isSending} indicator={antIcon}>
        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
          <Form.Item label="Промокод">
            {getFieldDecorator('code', {
              rules: [{ required: true, message: 'Введите' }],
              initialValue: promocodeForm.code,
            })(
              <Input
                placeholder="Введите"
                disabled={disableAll}
                onChange={e =>
                  this.props.setFieldForPromocode('code', e.target.value)
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Скидка, %">
            {getFieldDecorator('discount', {
              rules: [{ required: true, message: 'Введите' }],
              initialValue: promocodeForm.discount,
            })(
              <InputNumber
                placeholder="Введите"
                min={1}
                max={99}
                disabled={disableAll}
                onChange={value =>
                  this.props.setFieldForPromocode('discount', value)
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Дата действия с">
            {getFieldDecorator('date_valid_from', {
              rules: [{ required: true, message: 'Выберите дату' }],
              initialValue: promocodeForm.date_valid_from
                ? moment(promocodeForm.date_valid_from, 'YYYY-MM-DD')
                : null,
            })(
              <DatePicker
                format={'DD.MM.YYYY'}
                disabled={disableAll}
                placeholder="Выберите дату"
                allowClear={false}
                onChange={(date, dateString) =>
                  this.props.setFieldForPromocode(
                    'date_valid_from',
                    moment(dateString, 'DD.MM.YYYY').format(
                      'YYYY-MM-DD'
                    )
                  )
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Дата действия до">
            {getFieldDecorator('date_valid_to', {
              rules: [{ required: true, message: 'Выберите дату' }],
              initialValue: promocodeForm.date_valid_to
                ? moment(promocodeForm.date_valid_to, 'YYYY-MM-DD')
                : null,
            })(
              <DatePicker
                format={'DD.MM.YYYY'}
                disabled={disableAll}
                placeholder="Выберите дату"
                allowClear={false}
                onChange={(date, dateString) =>
                  this.props.setFieldForPromocode(
                    'date_valid_to',
                    moment(dateString, 'DD.MM.YYYY').format(
                      'YYYY-MM-DD'
                    )
                  )
                }
              />
            )}
          </Form.Item>

          {!disableAll && (
            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" htmlType="submit">
                Сохранить
              </Button>
            </Form.Item>
          )}
        </Form>
      </Spin>
    ) : null
  }
}

const mapStateToProps = store => {
  return {
    promocodes: store.promocodes,
    user: store.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setFieldForPromocode: (field, value) =>
      dispatch(setFieldForPromocode(field, value)),
    createPromocode: formObj => dispatch(createPromocode(formObj)),
    updatePromocode: (id, formObj) => dispatch(updatePromocode(id, formObj)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'PromocodeForm' })(PromocodeForm))