import React, { PureComponent } from 'react'
import {
  Row,
  Col,
  Button,
  Input,
  InputNumber,
  Modal,
  Table,
  Popconfirm,
  Alert
} from 'antd'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'

import {
  setFieldForOrder,
  closeModal,
  getOrder,
  refundOrder,
  getOrderBySoldTicketId,

  getSubscriptionOrder,
  refundSubscriptionOrder,
  getOrderBySoldSubscriptionId,

  getCertificateOrder,
  refundCertificateOrder,
  getOrderBySoldCertificateId,
} from '../../../redux/actions/OrderActions'

class Order extends PureComponent {
  state = {
    returnRrn: '',
  }

  getOrderStatusName(status) {
    let statusName = 'неизвестно'

    switch (status) {
      case 'ACTIVE':
        statusName = 'активен'
        break
      case 'PAID':
        statusName = 'оплачен'
        break
      case 'CANCELED':
        statusName = 'отменен'
        break
      case 'RETURNED':
        statusName = 'возвращен'
        break
      default:
        break
    }

    return statusName
  }

  getRefundMessages(
    isPayWithPushka,
    orderCardSum,
    orderRefundSum
  ) {
    let refundMessages = []

    if (isPayWithPushka && orderCardSum > 0) {
      refundMessages.push(<div key={0}>
        <strong>Сумма возврата на Пушкинскую карту:</strong>{' '}
        {orderCardSum} руб.
      </div>)

      if (orderRefundSum > orderCardSum) {
        refundMessages.push(<div key={1}>
          <strong>Сумма возврата по заявлению:</strong>{' '}
          {orderRefundSum - orderCardSum} руб.
        </div>)
      }

      refundMessages.push(<div key={2} style={{ marginTop: '0.5rem' }}>
        Верните всю сумму, оплаченную Пушкинской картой, через личный кабинет банка.
        После этого укажите RRN транзакции возврата.
      </div>)

      if (orderRefundSum > orderCardSum) {
        refundMessages.push(<div key={3} style={{ marginTop: '0.5rem' }}>
          Для возврата оставшейся суммы заказа, которая была оплачена сертификатом,
          примите у покупателя заявление на возврат с реквизитами банковской карты.
        </div >)
      }
    } else if (orderRefundSum > orderCardSum) {
      refundMessages = <>
        {orderCardSum > 0 && (
          <div>
            <strong>Сумма возврата на банковскую карту:</strong>{' '}
            {orderCardSum} руб.
          </div>
        )}
        <div>
          <strong>Сумма возврата по заявлению:</strong>{' '}
          {orderRefundSum - orderCardSum} руб.
        </div>
        <div style={{ marginTop: '0.5rem' }}>
          Для возврата суммы заказа, которая была оплачена сертификатом,
          примите у покупателя заявление на возврат с реквизитами банковской карты.
        </div>
      </>
    }

    return refundMessages
  }

  getDiscountedPrice(price, discountPercent) {
    return Math.round(price * (100 - discountPercent) / 100)
  }

  render() {
    const {
      filterForm,
      modalData,
      ticketForm,

      subscriptionFilterForm,
      subscriptionModalData,
      subscriptionForm,

      certificateFilterForm,
      certificateModalData,
      certificateForm,
    } = this.props.order

    const columns = [
      {
        title: 'Категория',
        dataIndex: 'category',
        key: 'category',
      },
      {
        title: 'Цена приобретения',
        dataIndex: 'price',
        key: 'price',
      },
      {
        title: 'Использован',
        dataIndex: 'used',
        key: 'used',
      },
    ]

    const ticketsUrl = process.env.REACT_APP_TICKETS_DOMAIN
    const REFUND_USED_SUBSCRIPTIONS = process.env.REACT_APP_REFUND_USED_SUBSCRIPTIONS

    // tickets START
    const dataSource = modalData.tickets.map(item => ({
      key: item.ticket_id,
      category: item.category_name,
      price: this.getDiscountedPrice(item.price, modalData.promocode_discount_percent),
      used: item.was_used ? 'Да, ' + item.used_date : 'Нет',
    }))

    let ticketOrderSum = 0
    let ticketOrderCardSum = 0
    let ticketOrderRefundSum = 0
    let numUsedTickets = 0

    modalData.tickets.forEach(item => {
      const ticketPrice = this.getDiscountedPrice(item.price, modalData.promocode_discount_percent)

      ticketOrderSum += ticketPrice

      if (item.was_used) {
        numUsedTickets++
      } else {
        ticketOrderRefundSum += ticketPrice
      }
    })

    ticketOrderCardSum = ticketOrderSum - modalData.discount

    const ticketOrderRefundMessages = this.getRefundMessages(
      modalData.is_pay_with_pushka,
      ticketOrderCardSum,
      ticketOrderRefundSum
    )
    // tickets END

    // subscriptions START
    const dataSourceSubscriptions = subscriptionModalData.order.subscriptions.map(item => ({
      key: item.subscription_id,
      category: item.category_name,
      price: this.getDiscountedPrice(
        item.price,
        subscriptionModalData.order.promocode_discount_percent
      ),
      used: item.visits_log.length > 0 ? 'Да' : 'Нет',
    }))

    let subscriptionOrderSum = 0
    let subscriptionOrderCardSum = 0
    let subscriptionOrderRefundSum = 0
    let numUsedSubscriptions = 0

    subscriptionModalData.order.subscriptions.forEach(item => {
      const subscriptionPrice = this.getDiscountedPrice(
        item.price,
        subscriptionModalData.order.promocode_discount_percent
      )

      subscriptionOrderSum += subscriptionPrice

      if (item.visits_log.length > 0) {
        numUsedSubscriptions++
      } else {
        subscriptionOrderRefundSum += subscriptionPrice
      }
    })

    subscriptionOrderCardSum = subscriptionOrderSum - subscriptionModalData.order.discount

    const subscriptionOrderRefundMessages = this.getRefundMessages(
      subscriptionModalData.order.is_pay_with_pushka,
      subscriptionOrderCardSum,
      subscriptionOrderRefundSum
    )
    // subscriptions END

    // certificates START
    const certificateOrderSum = certificateModalData.order.price
    const certificateOrderRefundSum = certificateModalData.order.remaining_price
    // certificates END

    const refundConfirmMessage = (type) => <>
      Вы уверены, что хотите вернуть заказ? <br />
      Покупатель получит электронный чек возврата, а <br />
      данные об отмене неиспользованных {type}, <br />
      участвующих в программе Пушкинская карта, <br />
      будут переданы в МинЦифру. <br />
      <b>Отменить этодействие будет невозможно.</b>
    </>

    return (
      <div>
        <Helmet>
          <title>Возврат</title>
        </Helmet>

        <h2>Возврат билетов</h2>

        <h3>По номеру заказа</h3>

        <Row gutter={16} className="Activity__settings">
          <Col span={24}>
            № заказа{' '}
            <InputNumber
              min={1}
              value={filterForm.order_id}
              style={{ width: '100px', marginRight: '5px', marginLeft: '5px' }}
              onChange={value => {
                this.props.setFieldForOrder('order_id', value, 'filterForm')
              }}
            />
            <Button
              type="primary"
              onClick={() => {
                this.props.getOrder(filterForm.order_id)
                this.setState({ refundRrn: '' })
              }}
              disabled={!filterForm.order_id}
              loading={filterForm.isLoadingOrder}
            >
              Проверить
            </Button>
          </Col>
        </Row>

        <h3>По номеру билета</h3>

        <Row gutter={16} className="Activity__settings">
          <Col span={24}>
            № билета{' '}
            <InputNumber
              min={1}
              value={ticketForm.ticket_id}
              style={{ width: '100px', marginRight: '5px', marginLeft: '5px' }}
              onChange={value => {
                this.props.setFieldForOrder('ticket_id', value, 'ticketForm')
              }}
            />
            <Button
              type="primary"
              onClick={() => {
                this.props.getOrderBySoldTicketId(ticketForm.ticket_id)
                this.setState({ refundRrn: '' })
              }}
              disabled={!ticketForm.ticket_id}
              loading={ticketForm.isLoadingOrder}
            >
              Получить
            </Button>
          </Col>
        </Row>

        <Modal
          title={`Заказ №${filterForm.order_id ? filterForm.order_id : ''}`}
          centered
          visible={modalData.isOpen}
          footer={
            <Button type="primary" onClick={() => this.props.closeModal()}>
              Закрыть
            </Button>
          }
          onCancel={() => this.props.closeModal()}
        >
          {!modalData.order_id && (
            <p style={{ textAlign: 'center' }}>
              <strong>Заказ не найден</strong>
            </p>
          )}

          {modalData.order_id && (
            <>
              <p>
                <strong>Покупатель:</strong> {modalData.user_fio},{' '}
                {modalData.user_phone}, {modalData.user_email}
                <br />
                <strong>Дата создания:</strong> {modalData.created_date}
                <br />
                <strong>Создан через ДИС:</strong> {modalData.is_created_from_dis === 1 ? 'да' : 'нет'}
                <br />
                <strong>Статус:</strong>{' '}
                {this.getOrderStatusName(modalData.status)}
                <br />
                <strong>Оплата Пушкинской картой:</strong> {modalData.is_pay_with_pushka === 1 ? 'да' : 'нет'}
                <br />
                <strong>Оплачено картой:</strong>{' '}
                {ticketOrderCardSum} руб.

                {modalData.discount > 0 && (
                  <>
                    <br />
                    <strong>Оплачено сертификатом:</strong>{' '}
                    {modalData.discount} руб.
                  </>
                )}

                {modalData.promocode_discount > 0 && (
                  <>
                    <br />
                    <strong>Скидка по промокоду:</strong>{' '}
                    {modalData.promocode_discount} руб. ({modalData.promocode_discount_percent}%)
                  </>
                )}
              </p>

              <p>
                <strong>Билет:</strong>{' '}
                <a
                  href={`${ticketsUrl}/ticket/${modalData.parent_ticket.parent_ticket_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {modalData.parent_ticket.parent_ticket_title}
                </a>
                <br />
                <strong>Билетов в заказе:</strong> {modalData.tickets.length} шт
                <br />
                <strong>Использовано:</strong> {numUsedTickets} шт
              </p>

              {modalData.status === 'PAID' &&
                modalData.tickets.length > numUsedTickets &&
                modalData.is_created_from_dis === 0 && (
                  <>
                    <Alert
                      message={
                        <>
                          <strong>Cумма возврата общая:</strong>{' '}
                          {ticketOrderRefundSum} руб.{' '}
                          {ticketOrderRefundMessages}
                        </>
                      }
                      type="info"
                      showIcon
                      style={{ marginBottom: '1rem' }}
                    />

                    <p>
                      <Input
                        value={this.state.refundRrn}
                        onChange={(e) => this.setState({ refundRrn: e.target.value })}
                        placeholder={
                          "RRN транзакции возврата " +
                          (modalData.is_pay_with_pushka === 1 && ticketOrderCardSum > 0 ?
                            '(обязательно для ПК)' : '')
                        }
                        style={{ marginBottom: '0.5rem' }}
                        maxLength={12}
                      />
                      <Popconfirm
                        title={refundConfirmMessage('билетов')}
                        onConfirm={() =>
                          this.props.refundOrder(modalData.order_id, this.state.refundRrn)
                        }
                        okText="Да"
                        cancelText="Нет"
                      >
                        <Button
                          loading={modalData.isRefundingOrder}
                          disabled={
                            (
                              !this.state.refundRrn ||
                              this.state.refundRrn.length < 11
                            ) &&
                            modalData.is_pay_with_pushka === 1 &&
                            ticketOrderCardSum > 0
                          }
                        >
                          Вернуть
                        </Button>
                      </Popconfirm>
                    </p>
                  </>
                )}

              {modalData.status === 'PAID' &&
                modalData.is_created_from_dis === 1 && (
                  <Alert
                    type="warning"
                    showIcon
                    message="Заказ создан через ДИС. Возврат через Билетную систему невозможен."
                    style={{ marginBottom: '1rem' }}
                  />
                )}

              <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
              />
            </>
          )}
        </Modal>

        <br />

        <h2>Возврат абонементов</h2>

        <h3>По номеру заказа</h3>

        <Row gutter={16} className="Activity__settings">
          <Col span={24}>
            № заказа{' '}
            А-<InputNumber
              min={1}
              value={subscriptionFilterForm.order_id}
              style={{ width: '100px', marginRight: '5px', marginLeft: '5px' }}
              onChange={value => {
                this.props.setFieldForOrder('order_id', value, 'subscriptionFilterForm')
              }}
            />
            <Button
              type="primary"
              onClick={() => {
                this.props.getSubscriptionOrder(subscriptionFilterForm.order_id)
                this.setState({ refundRrn: '' })
              }}
              disabled={!subscriptionFilterForm.order_id}
              loading={subscriptionFilterForm.isLoadingOrder}
            >
              Проверить
            </Button>
          </Col>
        </Row>

        <h3>По номеру абонемента</h3>

        <Row gutter={16} className="Activity__settings">
          <Col span={24}>
            № абонемента{' '}
            <InputNumber
              min={1}
              value={subscriptionForm.subscription_id}
              style={{ width: '100px', marginRight: '5px', marginLeft: '5px' }}
              onChange={value => {
                this.props.setFieldForOrder('subscription_id', value, 'subscriptionForm')
              }}
            />
            <Button
              type="primary"
              onClick={() => {
                this.props.getOrderBySoldSubscriptionId(subscriptionForm.subscription_id)
                this.setState({ refundRrn: '' })
              }}
              disabled={!subscriptionForm.subscription_id}
              loading={subscriptionForm.isLoadingOrder}
            >
              Получить
            </Button>
          </Col>
        </Row>

        <Modal
          title={`Заказ №А-${subscriptionModalData.order.id ? subscriptionModalData.order.id : ''}`}
          centered
          visible={subscriptionModalData.isOpen}
          footer={
            <Button type="primary" onClick={() => this.props.closeModal('subscriptionModalData')}>
              Закрыть
            </Button>
          }
          onCancel={() => this.props.closeModal('subscriptionModalData')}
        >
          {!subscriptionModalData.order.id && (
            <p style={{ textAlign: 'center' }}>
              <strong>Заказ не найден</strong>
            </p>
          )}

          {subscriptionModalData.order.id && (
            <>
              <p>
                <strong>Покупатель:</strong> {subscriptionModalData.order.user_fio},{' '}
                {subscriptionModalData.order.user_phone}, {subscriptionModalData.order.user_email}
                <br />
                <strong>Дата создания:</strong> {subscriptionModalData.order.created_date}
                <br />
                <strong>Статус:</strong>{' '}
                {this.getOrderStatusName(subscriptionModalData.order.status)}
                <br />
                <strong>Оплата Пушкинской картой:</strong>{' '}
                {subscriptionModalData.order.is_pay_with_pushka === 1 ? 'да' : 'нет'}
                <br />
                <strong>Оплачено картой:</strong>{' '}
                {subscriptionOrderCardSum} руб.

                {subscriptionModalData.discount > 0 && (
                  <>
                    <br />
                    <strong>Оплачено сертификатом:</strong>{' '}
                    {subscriptionModalData.discount} руб.
                  </>
                )}

                {subscriptionModalData.order.promocode_discount > 0 && (
                  <>
                    <br />
                    <strong>Скидка по промокоду:</strong>{' '}
                    {subscriptionModalData.order.promocode_discount} руб.{' '}
                    ({subscriptionModalData.order.promocode_discount_percent}%)
                  </>
                )}
              </p>

              <p>
                <strong>Абонемент:</strong>{' '}
                <a
                  href={`${ticketsUrl}/subscription/${subscriptionModalData.order.parent_subscription.parent_subscription_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {subscriptionModalData.order.parent_subscription.parent_subscription_title}
                </a>
                <br />
                <strong>Абонементов в заказе:</strong> {subscriptionModalData.order.subscriptions.length} шт
                <br />
                <strong>Использовано:</strong> {numUsedSubscriptions} шт
              </p>

              {subscriptionModalData.order.status === 'PAID' &&
                (
                  subscriptionModalData.order.subscriptions.length > numUsedSubscriptions ||
                  REFUND_USED_SUBSCRIPTIONS
                ) && (
                  <>
                    <Alert
                      message={
                        <>
                          <strong>Cумма возврата общая:</strong>{' '}
                          {subscriptionOrderRefundSum} руб.{' '}
                          {subscriptionOrderRefundMessages}
                        </>
                      }
                      type="info"
                      showIcon
                      style={{ marginBottom: '1rem' }}
                    />

                    <p>
                      <Input
                        value={this.state.refundRrn}
                        onChange={(e) => this.setState({ refundRrn: e.target.value })}
                        placeholder={
                          "RRN транзакции возврата " +
                          (
                            subscriptionModalData.order.is_pay_with_pushka === 1 &&
                              subscriptionOrderCardSum > 0 ?
                              '(обязательно для ПК)' : ''
                          )
                        }
                        style={{ marginBottom: '0.5rem' }}
                        maxLength={12}
                      />
                      <Popconfirm
                        title={refundConfirmMessage('абонементов')}
                        onConfirm={() =>
                          this.props.refundSubscriptionOrder(
                            subscriptionModalData.order.id,
                            this.state.refundRrn
                          )
                        }
                        okText="Да"
                        cancelText="Нет"
                      >
                        <Button
                          loading={subscriptionModalData.isRefundingOrder}
                          disabled={
                            (
                              !this.state.refundRrn ||
                              this.state.refundRrn.length < 11
                            ) &&
                            subscriptionModalData.order.is_pay_with_pushka === 1 &&
                            subscriptionOrderCardSum > 0
                          }
                        >
                          Вернуть
                        </Button>
                      </Popconfirm>
                    </p>
                  </>
                )}

              <Table
                dataSource={dataSourceSubscriptions}
                columns={columns}
                pagination={false}
              />
            </>
          )}
        </Modal>

        <h2>Возврат сертификатов</h2>

        <h3>По номеру заказа</h3>

        <Row gutter={16} className="Activity__settings">
          <Col span={24}>
            № заказа{' '}
            С-<InputNumber
              min={1}
              value={certificateFilterForm.order_id}
              style={{ width: '100px', marginRight: '5px', marginLeft: '5px' }}
              onChange={value => {
                this.props.setFieldForOrder('order_id', value, 'certificateFilterForm')
              }}
            />
            <Button
              type="primary"
              onClick={() => {
                this.props.getCertificateOrder(certificateFilterForm.order_id)
                this.setState({ refundRrn: '' })
              }}
              disabled={!certificateFilterForm.order_id}
              loading={certificateFilterForm.isLoadingOrder}
            >
              Проверить
            </Button>
          </Col>
        </Row>

        <h3>По номеру сертификата</h3>

        <Row gutter={16} className="Activity__settings">
          <Col span={24}>
            № сертификата{' '}
            <InputNumber
              min={1}
              value={certificateForm.certificate_id}
              style={{ width: '100px', marginRight: '5px', marginLeft: '5px' }}
              onChange={value => {
                this.props.setFieldForOrder('certificate_id', value, 'certificateForm')
              }}
            />
            <Button
              type="primary"
              onClick={() => {
                this.props.getOrderBySoldCertificateId(certificateForm.certificate_id)
                this.setState({ refundRrn: '' })
              }}
              disabled={!certificateForm.certificate_id}
              loading={certificateForm.isLoadingOrder}
            >
              Получить
            </Button>
          </Col>
        </Row>

        <Modal
          title={`Заказ №С-${certificateModalData.order.id ? certificateModalData.order.id : ''}`}
          centered
          visible={certificateModalData.isOpen}
          footer={
            <Button type="primary" onClick={() => this.props.closeModal('certificateModalData')}>
              Закрыть
            </Button>
          }
          onCancel={() => this.props.closeModal('certificateModalData')}
        >
          {!certificateModalData.order.id && (
            <p style={{ textAlign: 'center' }}>
              <strong>Заказ не найден</strong>
            </p>
          )}

          {certificateModalData.order.id && (
            <>
              <p>
                <strong>Покупатель:</strong> {certificateModalData.order.user_fio},{' '}
                {certificateModalData.order.user_phone}, {certificateModalData.order.user_email}
                <br />
                <strong>Дата создания:</strong> {certificateModalData.order.created_date}
                <br />
                <strong>Статус:</strong>{' '}
                {this.getOrderStatusName(certificateModalData.order.status)}
                <br />
                <strong>Сумма:</strong>{' '}
                {certificateOrderSum} руб.
                <br />
                <strong>Остаток:</strong>{' '}
                {certificateOrderRefundSum} руб.
              </p>

              {certificateModalData.order.status === 'PAID' &&
                certificateOrderRefundSum > 0 && (
                  <>
                    <Alert
                      message={
                        <>
                          <strong>Cумма возврата:</strong>{' '}
                          {certificateOrderRefundSum} руб.
                        </>
                      }
                      type="info"
                      showIcon
                      style={{ marginBottom: '1rem' }}
                    />

                    <p>
                      <Input
                        value={this.state.refundRrn}
                        onChange={(e) => this.setState({ refundRrn: e.target.value })}
                        placeholder={
                          "RRN транзакции возврата "
                        }
                        style={{ marginBottom: '0.5rem' }}
                        maxLength={12}
                      />
                      <Popconfirm
                        title={<>
                          Вы уверены, что хотите вернуть заказ? <br />
                          Покупатель получит электронный чек возврата. <br />
                          <b>Отменить этодействие будет невозможно.</b>
                        </>}
                        onConfirm={() =>
                          this.props.refundCertificateOrder(
                            certificateModalData.order.id,
                            this.state.refundRrn
                          )
                        }
                        okText="Да"
                        cancelText="Нет"
                      >
                        <Button
                          loading={certificateModalData.isRefundingOrder}
                        >
                          Вернуть
                        </Button>
                      </Popconfirm>
                    </p>
                  </>
                )}
            </>
          )}
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = store => {
  return {
    order: store.order,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setFieldForOrder: (order_id, value, form = 'filterForm') =>
      dispatch(setFieldForOrder(order_id, value, form)),
    getOrder: id => dispatch(getOrder(id)),
    refundOrder: (id, refundRrn) => dispatch(refundOrder(id, refundRrn)),
    closeModal: (modal = 'modalData') => dispatch(closeModal(modal)),
    getOrderBySoldTicketId: (id) => dispatch(getOrderBySoldTicketId(id)),

    getSubscriptionOrder: id => dispatch(getSubscriptionOrder(id)),
    refundSubscriptionOrder: (id, refundRrn) => dispatch(refundSubscriptionOrder(id, refundRrn)),
    getOrderBySoldSubscriptionId: (id) => dispatch(getOrderBySoldSubscriptionId(id)),

    getCertificateOrder: id => dispatch(getCertificateOrder(id)),
    refundCertificateOrder: (id, refundRrn) => dispatch(refundCertificateOrder(id, refundRrn)),
    getOrderBySoldCertificateId: (id) => dispatch(getOrderBySoldCertificateId(id)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Order)
