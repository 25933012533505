export const PROMOCODES_LOAD_REQUEST = 'PROMOCODES/PROMOCODES_LOAD_REQUEST'
export const PROMOCODES_LOAD_SUCCESS = 'PROMOCODES/PROMOCODES_LOAD_SUCCESS'

export function getPromocodesList(pageNumber = 1, sort = null) {
  return dispatch => {
    let additionalParams = {}

    if (sort) {
      additionalParams.sort = sort.field
      additionalParams.sort_type = sort.type
    }

    dispatch({
      types: [PROMOCODES_LOAD_REQUEST, PROMOCODES_LOAD_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: '/promocode/get-promocodes-list',
          params: {
            page: pageNumber,
            ...additionalParams,
          },
        },
      },
    })
  }
}

export const COPY_PROMOCODE_REQUEST = 'PROMOCODES/COPY_PROMOCODE_REQUEST'
export const COPY_PROMOCODE_SUCCESS = 'PROMOCODES/COPY_PROMOCODE_SUCCESS'

export function copyPromocode(id) {
  return dispatch => {
    dispatch({
      types: [COPY_PROMOCODE_REQUEST, COPY_PROMOCODE_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/promocode/copy/${id}`,
        },
        id,
      },
    })
  }
}

export const DELETE_PROMOCODE_REQUEST = 'PROMOCODES/DELETE_PROMOCODE_REQUEST'
export const DELETE_PROMOCODE_SUCCESS = 'PROMOCODES/DELETE_PROMOCODE_SUCCESS'

export function deletePromocode(id) {
  return dispatch => {
    dispatch({
      types: [DELETE_PROMOCODE_REQUEST, DELETE_PROMOCODE_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/promocode/delete/${id}`,
        },
        id,
      },
    })
  }
}

export const OPEN_PROMOCODE_FORM = 'PROMOCODES/OPEN_PROMOCODE_FORM'

export function openPromocodeForm() {
  return dispatch => {
    dispatch({
      type: OPEN_PROMOCODE_FORM,
    })
  }
}

export const CLOSE_PROMOCODE_FORM = 'PROMOCODES/CLOSE_PROMOCODE_FORM'

export function closePromocodeForm() {
  return dispatch => {
    dispatch({
      type: CLOSE_PROMOCODE_FORM,
    })
  }
}

export const OPEN_EDIT_PROMOCODE_FORM = 'PROMOCODES/OPEN_EDIT_PROMOCODE_FORM'

export function openEditPromocodeForm(id) {
  return dispatch => {
    dispatch({
      type: OPEN_EDIT_PROMOCODE_FORM,
      payload: {
        id,
      },
    })
  }
}

// PROMOCODE FORM START

export const SET_FIELD_FOR_PROMOCODE = 'PROMOCODES/SET_FIELD_FOR_PROMOCODE'

export function setFieldForPromocode(field, value) {
  return dispatch => {
    dispatch({
      type: SET_FIELD_FOR_PROMOCODE,
      payload: {
        field,
        value,
      },
    })
  }
}

export const CREATE_PROMOCODE_REQUEST = 'PROMOCODES/CREATE_PROMOCODE_REQUEST'
export const CREATE_PROMOCODE_SUCCESS = 'PROMOCODES/CREATE_PROMOCODE_SUCCESS'

export function createPromocode(formObj) {
  return dispatch => {
    dispatch({
      types: [CREATE_PROMOCODE_REQUEST, CREATE_PROMOCODE_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/promocode/create`,
          params: {
            ...formObj,
          },
        },
      },
    })
  }
}

export const UPDATE_PROMOCODE_REQUEST = 'PROMOCODES/UPDATE_PROMOCODE_REQUEST'
export const UPDATE_PROMOCODE_SUCCESS = 'PROMOCODES/UPDATE_PROMOCODE_SUCCESS'

export function updatePromocode(id, formObj) {
  return dispatch => {
    dispatch({
      types: [UPDATE_PROMOCODE_REQUEST, UPDATE_PROMOCODE_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/promocode/update/${id}`,
          params: {
            ...formObj,
          },
        },
      },
    })
  }
}

// PROMOCODE FORM END