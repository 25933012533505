import produce from 'immer'
import moment from 'moment'

import {
  PROMOCODES_LOAD_REQUEST,
  PROMOCODES_LOAD_SUCCESS,
  COPY_PROMOCODE_REQUEST,
  COPY_PROMOCODE_SUCCESS,
  DELETE_PROMOCODE_REQUEST,
  DELETE_PROMOCODE_SUCCESS,
  OPEN_PROMOCODE_FORM,
  CLOSE_PROMOCODE_FORM,
  OPEN_EDIT_PROMOCODE_FORM,
  SET_FIELD_FOR_PROMOCODE,
  CREATE_PROMOCODE_REQUEST,
  CREATE_PROMOCODE_SUCCESS,
  UPDATE_PROMOCODE_REQUEST,
  UPDATE_PROMOCODE_SUCCESS,
} from '../actions/PromocodesActions'

const initialState = {
  isLoadingPromocodes: false,
  promocodes: [],
  animation: true,
  pageNumber: 1,
  isTotalReached: false,
  sort: {
    field: 'id',
    type: 'desc',
  },

  promocodeForm: {
    id: null,
    discount: null,
    code: null,
    date_valid_from: moment().format('YYYY-MM-DD'),
    date_valid_to: moment().format('YYYY-MM-DD'),

    usage_count: 0,

    isOpen: false,
    isSending: false,
  },

  isDeletingPromocode: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case PROMOCODES_LOAD_REQUEST:
      return produce(state, draftState => {
        draftState.isLoadingPromocodes = true
        draftState.isTotalReached = false

        const pageNumber = action.payload.request.params['page']
        if (pageNumber === 1) {
          draftState.promocodes = []
        }
        draftState.pageNumber = pageNumber

        if (action.payload.request.params['sort']) {
          draftState.sort.field = action.payload.request.params['sort']
          draftState.sort.type = action.payload.request.params['sort_type']
        } else {
          draftState.sort = initialState.sort
        }
      })
    case PROMOCODES_LOAD_SUCCESS:
      return produce(state, draftState => {
        draftState.isLoadingPromocodes = false
        draftState.animation = false

        if (draftState.pageNumber === 1) {
          draftState.promocodes = action.payload.data
        } else {
          draftState.isTotalReached = action.payload.data.length === 0

          draftState.promocodes = Array.from(
            new Map(
              [...draftState.promocodes, ...action.payload.data].map(obj => [
                obj.id,
                obj
              ])
            ).values()
          )
        }
      })
    case COPY_PROMOCODE_REQUEST:
      return produce(state, draftState => {
        draftState.animation = true
      })
    case COPY_PROMOCODE_SUCCESS:
      return produce(state, draftState => {
        const { data } = action.payload
        draftState.promocodes.unshift(data)
      })
    case DELETE_PROMOCODE_SUCCESS:
      return produce(state, draftState => {
        draftState.isDeletingPromocode = false
      })
    case DELETE_PROMOCODE_REQUEST:
      return produce(state, draftState => {
        draftState.isDeletingPromocode = true
        draftState.animation = true

        draftState.promocodes.splice(
          draftState.promocodes.findIndex(item => item.id === action.payload.id),
          1
        )
      })
    case CLOSE_PROMOCODE_FORM:
      return produce(state, draftState => {
        draftState.promocodeForm.isOpen = false
      })
    case OPEN_PROMOCODE_FORM:
      return produce(state, draftState => {
        draftState.promocodeForm = {
          ...initialState.promocodeForm,
          isOpen: true,
        }
      })
    case OPEN_EDIT_PROMOCODE_FORM:
      return produce(state, draftState => {
        let promocode = draftState.promocodes.find(
          item => item.id === action.payload.id
        )

        draftState.promocodeForm = {
          ...draftState.promocodeForm,

          isOpen: true,
          ...promocode,
        }
      })
    case SET_FIELD_FOR_PROMOCODE:
      return produce(state, draftState => {
        draftState.promocodeForm[action.payload.field] = action.payload.value
      })
    case CREATE_PROMOCODE_SUCCESS:
      return produce(state, draftState => {
        draftState.promocodeForm.isSending = false
        draftState.promocodeForm.isOpen = false
        const { data } = action.payload
        draftState.promocodes.unshift(data)
      })
    case CREATE_PROMOCODE_REQUEST:
      return produce(state, draftState => {
        draftState.promocodeForm.isSending = true
        draftState.animation = true
      })
    case UPDATE_PROMOCODE_SUCCESS:
      return produce(state, draftState => {
        draftState.promocodeForm.isSending = false
        draftState.promocodeForm.isOpen = false

        let promocodeIndex = draftState.promocodes.findIndex(
          item => item.id === action.payload.data.id
        )
        draftState.promocodes[promocodeIndex] = action.payload.data
      })
    case UPDATE_PROMOCODE_REQUEST:
      return produce(state, draftState => {
        draftState.promocodeForm.isSending = true
      })
    default:
      return state
  }
}